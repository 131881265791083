
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  height: 100%;
}
body {
  height: 100%;
  background: #fff;
  color: #174B8F;
  font-family: Arial, sans-serif;
}
#app {
  height: 100%;
}
a {
  color: #4e4a46;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #c94e50;
}
main {
  height: 100%;
  padding: 3em 2em;
  text-align: center;
  background: #b4bad2;
  overflow: scroll;
}
h1 {
  font-weight: 800;
  font-size: 3.75em;
}
.description {
  max-width: 20em;
  margin: 1.2em auto 1em;
  line-height: 1.3em;
  font-weight: 400;
}
.demo-buttons {
  font-size: 1em;
  max-width: 1200px;
  margin: 2em auto 3em;
  padding: 0 10em;
}
.demo-buttons a {
  display: inline-block;
  margin: 0.75em;
  padding: 1.35em 1.1em;
  width: 15em;
  background: #fffce1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 800;
  border-top-left-radius: 20px 50px;
  border-top-right-radius: 20px 50px;
  border-bottom-right-radius: 20px 50px;
  border-bottom-left-radius: 20px 50px;
  cursor: pointer;
}
.demo-buttons a.current-demo {
  background: #c94e50;
  color: #fffce1;
}
.side-button {
  display: inline-block;
  width: 5em;
  height: 2.5em;
  line-height: 2.5em;
  cursor: pointer;
  background: #fffce1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8em;
  font-weight: 800;
}
.side-button.left {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.side-button.right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.side-button.active {
  background: #c94e50;
  color: #fffce1;
}
@media screen and (max-width: 40em) {
  main {
    font-size: 80%;
  }
  h1 {
    font-size: 2.5em;
  }
  .demo-buttons {
    max-width: 900px;
    padding: 0 2em;
  }
}
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}
.right .bm-burger-button {
  left: initial;
  right: 36px;
}
.bm-burger-bars {
  background: #373a47;
}
.bm-morph-shape {
  fill: #373a47;
}
.bm-menu {
  background: #373a47;
}
.bm-menu a {
  color: #b8b7ad;
}
.bm-menu a:hover,
.bm-menu a:focus {
  color: #c94e50;
}
.bm-item-list a {
  padding: 0.8em;
}
.bm-item-list a span {
  margin-left: 10px;
  font-weight: 700;
}
#slide .bm-cross,
#stack .bm-cross {
  background: #bdc3c7;
}
#slide .bm-menu,
#stack .bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 20px;
  top: 70px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list { 
  color: #b8b7ad;
  padding: 0.8em;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.bm-menu-wrap {
  bottom: 0;
  left: 0;
}