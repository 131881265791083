.btn-signin {
  width: 400px;
  height: 20px;
}

#customBtn {
  display: inline-block;
  background: white;
  color: #444;
  width: 190px;
  border-radius: 5px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  white-space: nowrap;
}
#customBtn:hover {
  cursor: pointer;
}
span.label {
  font-family: serif;
  font-weight: normal;
}
span.icon {
  /* background: url('/identity/sign-in/g-normal.png') transparent 5px 50% no-repeat; */
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}
span.buttonText {
  display: inline-block;
  vertical-align: middle;
  padding-left: 42px;
  padding-right: 42px;
  font-size: 14px;
  font-weight: bold;
  /* Use the Roboto font that is loaded in the <head> */
  font-family: 'Arial', sans-serif;
}

.content {
  padding-left: 100px;
}

.profileName {
  padding-right: 20px;
  padding-bottom: 5px;
  vertical-align: bottom;
}

.navbar-header{
  margin-left:5px;
  width:100%;
}

.btn-facebook {
  background: #3B5998;
  border-radius: 0;
  color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #263961; 
}
.btn-facebook:link, .btn-facebook:visited {
  color: #fff;
}
.btn-facebook:active, .btn-facebook:hover {
  background: #263961;
  color: #fff;
}

.btn-instagram {
  background: #3F729B;
  border-radius: 0;
  color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #263961; 
}
.btn-instagram:link, .btn-instagram:visited {
  color: #fff;
}
.btn-instagram:active, .btn-instagram:hover {
  background: #3F729B;
  color: #fff;
}

.btn-linkedin {
  background: #007BB6;
  border-radius: 0;
  color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #263961; 
}
.btn-linkedin:link, .btn-linkedin:visited {
  color: #fff;
}
.btn-linkedin:active, .btn-linkedin:hover {
  background: #007BB6;
  color: #fff;
}


    @media all and (max-width:480px) {
      .btn-gordoneer { width: 100%; display:block; }
   }  

   .buttontoolbarMargin {
     margin-top: 20px;
   }

   .logoImage {
     margin-top: 10px;
     margin-left: 30px;
   }
   
   .jumbotrontext {
     text-align: center;
   }
   .pass {
    list-style-type: none;
   }

   .first img{
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .checklistUl {
    list-style-type: none;
  }

  .photoItem {
  width: 100%;
  height: 70px;
  background: #fdfdfd;
  border-radius: 2;
  color: #000;
  border-width: 1px;
  border-style: solid;
  border-color: #dfdfdf; 
  padding: 10px;
  margin: 5px;
  }
  .photoLeft {
    float:left;
    vertical-align: top;
  }
  .photoRight {
    padding-left: 15px;
    float: left;
  }
  .photoCheck {
    float: right;
    font-size:2em;
     color:Green
  }
.photoItem > * {
    font-size: 10px;
  }
  .photoCheck > * {
    font-size: 20px;
    
  }
  h4 {
    font-size: 12px;
  }